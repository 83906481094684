import React from "react";
import { BsInstagram } from "react-icons/bs";
import { GrLinkedinOption } from "react-icons/gr";
import { FaMediumM, FaBlog, FaTwitter, FaCamera, FaCertificate } from "react-icons/fa";
import { motion } from "framer-motion";

import "./Footer.scss";
const Footer = () => {
  const parentVariant = {
    view: {
      opacity: [0, 1],
      transition: {
        duration: 0.1,
        when: "beforeChildren",
        staggerChildren: 0.15,
      },
    },
  };

  const childSocialVariant = {
    view: {
      y: [-300, 0],
      opacity: [0, 1],
      transition: {
        opacity: {
          duration: 0.6,
        },
        duration: 0.3,
        ease: "easeInOut",
      },
    },
  };
  const childCopyVariant = {
    view: {
      y: [-100, 0],
      opacity: [0, 1],
      transition: {
        opacity: {
          duration: 0.6,
        },
        duration: 0.3,
        ease: "easeInOut",
      },
    },
  };

  return (
    <div className="app__flex app__footer">
      <motion.div
        className="app__footer-contacts app__flex"
        variants={parentVariant}
        whileInView="view"
      >
        <motion.div
  className="app__flex"
  variants={childSocialVariant}
  whileInView="view"
>
  <a
    href="https://www.linkedin.com/in/beimnet-zewdu-kebede"
    target="_blank"
    rel="noreferrer"
    className="app__flex"
    aria-label="LinkedIn profile of Beimnet Zewdu Kebede"
  >
    <GrLinkedinOption />
  </a>
</motion.div>

<motion.div
  className="app__flex"
  variants={childSocialVariant}
  whileInView="view"
>
  <a
    href="https://medium.com/@beimnetzewdu"
    target="_blank"
    rel="noreferrer"
    className="app__flex"
    aria-label="Medium profile of Beimnet Zewdu"
  >
    <FaMediumM />
  </a>
</motion.div>

<motion.div
  className="app__flex"
  variants={childSocialVariant}
  whileInView="view"
>
  <a
    href="https://www.instagram.com/beimnetzewdu/"
    target="_blank"
    rel="noreferrer"
    className="app__flex"
    aria-label="Instagram profile of Beimnet Zewdu"
  >
    <BsInstagram />
  </a>
</motion.div>

<motion.div
  className="app__flex"
  variants={childSocialVariant}
  whileInView="view"
>
  <a
    href="https://twitter.com/beimnetzewduk"
    target="_blank"
    rel="noreferrer"
    className="app__flex"
    aria-label="Twitter profile of Beimnet Zewdu"
  >
    <FaTwitter />
  </a>
</motion.div>

<motion.div
  className="app__flex"
  variants={childSocialVariant}
  whileInView="view"
>
  <a
    href="http://myblog.beimnetzewdu.com/"
    target="_blank"
    rel="noreferrer"
    className="app__flex"
    aria-label="Personal blog of Beimnet Zewdu"
  >
    <FaBlog />
  </a>
</motion.div>

<motion.div
  className="app__flex"
  variants={childSocialVariant}
  whileInView="view"
>
  <a
    href="https://photography.beimnetzewdu.com/"
    target="_blank"
    rel="noreferrer"
    className="app__flex"
    aria-label="Photography portfolio of Beimnet Zewdu"
  >
    <FaCamera />
  </a>
</motion.div>

<motion.div
  className="app__flex"
  variants={childSocialVariant}
  whileInView="view"
>
  <a
    href="https://www.credly.com/users/beimnetzewdukebede/badges"
    target="_blank"
    rel="noreferrer"
    className="app__flex"
    aria-label="Credly certification badges of Beimnet Zewdu"
  >
    <FaCertificate />
  </a>
</motion.div>


      </motion.div>
      <motion.div
        className="app__footer-copyrights"
        variants={parentVariant}
        whileInView="view"
      >
        <motion.p
          className="p-text"
          variants={childCopyVariant}
          whileInView="view"
        >
          &copy; {new Date().getFullYear()} Beimnet Zewdu
        </motion.p>
        <motion.p
          className="p-text"
          variants={childCopyVariant}
          whileInView="view"
        >
          ALL RIGHTS RESERVED
        </motion.p>
      </motion.div>
    </div>
  );
};

export default Footer;
