import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { HiChevronLeft, HiChevronRight } from "react-icons/hi";

import { AppWrap, MotionWrap } from "../../wrapper";
import { client, urlFor } from "../../client";
import "./Testimonial.scss";
import DocumentMeta from "react-document-meta";

const Testimonial = () => {
  const [testimonials, setTestimonials] = useState([]);
  const [brands, setBrands] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const brandsQuery = "*[_type == 'brands']";
    const testimonialsQuery = "*[_type == 'testimonials']";


    client.fetch(testimonialsQuery).then((testimonialsData) => {
      setTestimonials(testimonialsData);
    });

    client.fetch(brandsQuery).then((brandsData) => {
      setBrands(brandsData);
    });
  }, []);

  const handleClick = (index) => {
    setCurrentIndex(index);
  };

  const test = testimonials[currentIndex];

  const meta = {
    title: 'Beimnet Zewdu - Portfolio Website',
    description: 'This is the portfolio website, of Beimnet Zewdu a mobile and web applications developer in Addis Ababa, Ethiopia.',
    canonical: 'https://beimnetzewdu.com',
    meta: {
        charset: 'utf-8',
        name: {
            keywords: 'Beimnet, Beimnet Zewdu, Beimnet Zewdu Kebede, Mobile Developer, Web Developer, Website Developer, Website Design, Android Developer, Android App Developer, Mobile Development, Android Development, Android App Development, Best Mobile Developer, Best Mobile App Developer, Best Android App Developer, Best Web Developer,  Best Mobile Developer in Ethiopia, Best Mobile Developer in Addis, Best Mobile Developer in Addis Ababa, Best Mobile Developer in Addis Abeba, Best Web Developer in Ethiopia, Best Web Developer in Addis Ababa, Best Web Developer in Addis Abeba, Best Developer in Ethiopia, Best Developer in Addis Ababa, Beautiful Website, React Developer'
        }
    }
  };

  return (
    <>
       <DocumentMeta {...meta} />
      <h2 className="head-text">
        See what <span>Others</span> say about <span>me</span>!
      </h2>
      {testimonials.length && (
        <>
          <div className="app__testimonial-carousel app__flex">
            <img src={urlFor(test.imgurl)} alt={test.name} />
            <div className="app__testimonial-content">
              <p className="p-text" id="p-text-id">{test.feedback}</p>
              <div>
                <h4 className="bold-text">{test.name}</h4>
                <h6 className="p-text">{test.company}</h6>
              </div>
            </div>
          </div>

          <div className="app__testimonials-btns app__flex">
            <div
              className="app__flex"
              onClick={() =>
                handleClick(
                  currentIndex === 0
                    ? testimonials.length - 1
                    : currentIndex - 1
                )
              }
            >
              <HiChevronLeft />
            </div>
            <div
              className="app__flex"
              onClick={() =>
                handleClick(
                  currentIndex === testimonials.length - 1
                    ? 0
                    : currentIndex + 1
                )
              }
            >
              <HiChevronRight />
            </div>
          </div>
        </>
      )}

      <div className="app__testimonials-brands app__flex">
        {brands.map((brand) => (
            <a href={brand.brandLink} target="_blank" rel="noreferrer">
          <motion.div
            whileInView={{ opacity: [0, 1] }}
            transition={{ duration: 0.5, type: "tween" }}
            key={brand._id}>
            <img src={urlFor(brand.imgUrl)} alt={brand.name}/>

          </motion.div>
          </a>
        ))}
      </div>
    </>
  );
};

export default AppWrap(
  MotionWrap(Testimonial, "app__testimonial"),
  "testimonials",
  "app__primarybg"
);
