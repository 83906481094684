import react from "../assets/react-skill.png";
import node from "../assets/node.png";
import android from "../assets/android.png";

import mobile from "../assets/mobile.png";
import api from "../assets/api.png";


import programmer from "../assets/programmer.svg";
import programmer_reduced from "../assets/programmer_reduced.webp";

import circle from "../assets/circle.svg";
import logo from "../assets/logo.png";
import logo_new from "../assets/logo_new.png";

import email from "../assets/email.png";
import github from '../assets/github.png';
import xtwitter from '../assets/twitter.png';
import linkedin from '../assets/linkedin.png';
import instagram from '../assets/instagram.png';
import sass from '../assets/sass.png';

const images = {
  email,
  sass,
  github,
  xtwitter,
  linkedin,
  instagram,
  mobile,
  api,
  node,
  react,
  programmer,
  programmer_reduced,
  circle,
  logo,
  logo_new,
  android,
};

export default images;
