import React from "react";
import { motion } from "framer-motion";
import { useTypewriter, Cursor } from "react-simple-typewriter";

import { AppWrap } from "../../wrapper";
import { images } from "../../constants";
import "./Header.scss";
import DocumentMeta from "react-document-meta";

const scaleVariants = {
  whileInView: {
    scale: [0, 1],
    opacity: [0, 1],
  },
  transition: {
    duration: 0.5,
    ease: "easeInOut",
  },
};

const headerSkillVariants = {
  visible: {
    rotate: [-120, 0],
    y: -30,
    x: 50,
    transition: {
      rotate: { delay: 0.5, duration: 0.3, ease: "easeOut" },
      y: {
        repeat: Infinity,
        repeatType: "reverse",
        duration: 2,
        ease: "easeOut",
      },
      x: {
        repeat: Infinity,
        repeatType: "mirror",
        duration: 5,
        ease: "easeOut",
      },
    },
  },
  hover: {
    boxShadow: "0 0 20px rgba(0,0,0,0.2)",
  },
};

const Header = () => {
  const { text: tagText } = useTypewriter({
    words: ["Full Stack Developer", "Web Applications Developer",
    "Mobile Applications Developer", "Computer Scientist", 
    "Tech Blogger", "Critical Thinker", "Problem Solver", "Cybersecurity Enthusiast",
     "Photographer"],
    typeSpeed: 150,
    loop: true,
  });

  const meta = {
    title: 'Beimnet Zewdu - Portfolio Website',
    description: 'This is the portfolio website, of Beimnet Zewdu a mobile and web applications developer in Addis Ababa, Ethiopia.',
    canonical: 'https://beimnetzewdu.com',
    meta: {
        charset: 'utf-8',
        name: {
            keywords: 'Beimnet, Beimnet Zewdu, Beimnet Zewdu Kebede, Mobile Developer, Web Developer, Website Developer, Website Design, Android Developer, Android App Developer, Mobile Development, Android Development, Android App Development, Best Mobile Developer, Best Mobile App Developer, Best Android App Developer, Best Web Developer,  Best Mobile Developer in Ethiopia, Best Mobile Developer in Addis, Best Mobile Developer in Addis Ababa, Best Mobile Developer in Addis Abeba, Best Web Developer in Ethiopia, Best Web Developer in Addis Ababa, Best Web Developer in Addis Abeba, Best Developer in Ethiopia, Best Developer in Addis Ababa, Beautiful Website, React Developer'
        }
    }
  };

  return (
    <div className="app__header app__flex">
         <DocumentMeta {...meta} />
         <link rel="preload" src={images.programmer_reduced} />
      <motion.div
        whileInView={{ x: [-100, 0], opacity: [0, 1] }}
        transition={{ duration: 0.5 }}
        className="app__header-info"
      >
        <div className="app__header-badge">
          <div className="badge-cmp app__flex">
            <span class="wave">👋🏾</span>
            <div style={{ marginLeft: 20 }}>
              <p className="p-text">Well Hello There, I am</p>
              <p className="head-text">Beimnet</p>
            </div>
          </div>
          <div className="tag-cmp app__flex">
            <p className="p-text">{tagText}</p>
            <Cursor cursorStyle="I" />
            {/* <p className="p-text">Enthusiast</p> */}
          </div>
        </div>
      </motion.div>

      <motion.div
        whileInView={{ opacity: [0, 1] }}
        transition={{ duration: 0.5, delayChildren: 0.5 }}
        className="app__header-img"
      >

        <motion.img
          src={images.programmer_reduced}
          alt="profile_bg"
          whileInView={{ y: [-100, 0] }}
          loading="eager" 
          fetchpriority="high"
        />

      </motion.div>

      <motion.div
        variants={scaleVariants}
        whileInView={scaleVariants.whileInView}
        className="app__header-circles">

        {[images.react, images.android, images.sass, images.node].map((circle, index) => (
          <motion.div
            variants={headerSkillVariants}
            whileInView="visible"
            whileHover="hover"
            drag
            dragConstraints={{ left: 10, right: 50, top: 10, bottom: 10 }}
            dragElastic={0.1}
            className="circle-cmp app__flex"
            key={`circle-${index}`}
          >
            <img src={circle} alt="circle" />
          </motion.div>
        ))}
      </motion.div>
      
    </div>
  );
};

export default AppWrap(Header, "home");
